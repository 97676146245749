import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css';

export default class ServiceSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.services-swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const ServicesSwiper = new Swiper('.services-swiper', {

                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },

                  autoplay: {
                    delay: 1,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: true,
                  },

                slidesPerView: 1,
                speed: 9000,
                spaceBetween: 20,
                loop: true,
                autoHeight: true,

                breakpoints: {
                    1025: {
                        slidesPerView: 3, 
                        autoplay: {
                            delay: 1,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: true,
                        }
                        
                    }
                },

                modules: [ Navigation, Autoplay ],

            })

            // $('.swiper-slide').on('mouseover', function() {
            //     swiper.autoplay.stop();
            // });
              
            //   $('.swiper-slide').on('mouseout', function() {
            //     swiper.autoplay.start();
            // });
        });
    }
}