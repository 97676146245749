import '@styles/main.scss';
import ServiceSwiper from './components/services-slider';

class App {
    constructor() {
        new ServiceSwiper();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
